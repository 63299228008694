import React from "react";
import { IoArrowBack, IoArrowForward, IoClose } from "react-icons/io5";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles

const MediaModal = ({ isModalOpen, closeModal, modalData, handleTagClick }) => {
  if (!isModalOpen || !modalData) return null;

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    const formattedString = string.replace(/-/g, " ");
    return formattedString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div
      className="fixed inset-0 z-[999999] flex items-center justify-center  bg-black bg-opacity-55 backdrop-blur-lg"
      onClick={closeModal}
    >
      <div
        className="relative flex flex-col md:flex-col  lg:flex-row bg-white rounded-xl shadow-lg w-[100%] h-[100%] md:w-[95%] md:h-[95%] lg:w-[95%] lg:h-[95%]overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Left Side: Media (Images/Videos) */}
        <div className="w-12/12 md:w-[100%] lg:w-9/12    bg-black  relative flex items-center justify-center">
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-white text-3xl z-50"
          >
            <IoClose />
          </button>

          {modalData.images && modalData.images.length === 1 ? (
            <div
              className="w-full h-[80vh] flex items-center justify-center" // Adjust container height
            >
              <img
                src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${modalData.images[0].file}`}
                className="w-full max-h-full object-fill"
                alt="Post"
              />
            </div>
          ) : modalData.images && modalData.images.length > 1 ? (
            <Carousel
              showThumbs={false}
              showStatus={false}
              renderArrowPrev={(onClickHandler, hasPrev) =>
                hasPrev && (
                  <button
                    onClick={onClickHandler}
                    className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md text-gray-700 hover:bg-gray-200 transition duration-300 z-50"
                  >
                    <IoArrowBack size={24} />
                  </button>
                )
              }
              renderArrowNext={(onClickHandler, hasNext) =>
                hasNext && (
                  <button
                    onClick={onClickHandler}
                    className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md text-gray-700 hover:bg-gray-200 transition duration-300"
                  >
                    <IoArrowForward size={24} />
                  </button>
                )
              }
            >
              {modalData.images.map((image, index) => (
                <div
                  key={index}
                  className="w-full h-[80vh] flex items-center justify-center" // Adjust container height
                >
                  <img
                    src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${image.file}`}
                    className="w-full max-h-full object-contain" // Ensure full width and height containment
                    alt={`Post Image ${index + 1}`}
                  />
                </div>
              ))}
            </Carousel>
          ) : modalData.videos && modalData.videos.length === 1 ? (
            <video
              src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${modalData.videos[0].file}`}
              controls
              className="rounded-xl  object-contain"
            />
          ) : modalData.videos && modalData.videos.length > 1 ? (
            <Carousel
              showThumbs={false}
              showStatus={false}
              renderArrowPrev={(onClickHandler, hasPrev) =>
                hasPrev && (
                  <button
                    onClick={onClickHandler}
                    className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md text-gray-700 hover:bg-gray-200 transition duration-300 z-50"
                  >
                    <IoArrowBack size={24} />
                  </button>
                )
              }
              renderArrowNext={(onClickHandler, hasNext) =>
                hasNext && (
                  <button
                    onClick={onClickHandler}
                    className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md text-gray-700 hover:bg-gray-200 transition duration-300"
                  >
                    <IoArrowForward size={24} />
                  </button>
                )
              }
            >
              {modalData.videos.map((video, index) => (
                <div key={index} className="w-full flex">
                  <video
                    src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${video.file}`}
                    controls
                    className="rounded-xl w-full max-h-full object-contain"
                  />
                </div>
              ))}
            </Carousel>
          ) : null}
        </div>

        {/* Right Side: Info */}
        <div className=" lg:w-3/12 md:w-full w-full  p-4 flex flex-col justify-between h-full overflow-y-auto scrollbar-hide ">
          <div>
            <div className="flex items-center mb-4">
              {modalData.user.profile_image !== "default.png" ? (
                <img
                  className={`w-8 h-8 rounded-full object-cover text-[#E7831D] ${
                    modalData.user.type === "employer"
                      ? "ring-2 ring-[#E7831D]"
                      : "ring-2 ring-gray-700"
                  }`}
                  src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${modalData.user.profile_image}`}
                  alt="User Avatar"
                />
              ) : (
                <FaUserCircle
                  className={`w-8 h-8 text-[2rem] text-gray-300 ${
                    modalData.user.type === "employer"
                      ? "ring-2 ring-[#E7831D]"
                      : "ring-2 ring-gray-700"
                  }`}
                />
              )}
              <div className="flex flex-col ml-2">
                <Link
                  to={`/user/${modalData.user.id}`}
                  className="text-sm font-bold text-[#5B5B5B]"
                >
                  {modalData.user.first_name} {modalData.user.last_name}
                </Link>
                <span className="text-xs flex items-center">
                  <span className="text-xs text-[#5B5B5B]">
                    {modalData.user.username}
                  </span>
                  <GoDotFill
                    className={`ml-1 text-xs mr-1 ${
                      modalData.user.type === "job-seeker"
                        ? "text-black"
                        : "text-[#E7831D]"
                    }`}
                  />
                  <span
                    className={`ml-1 text-xs mr-1 ${
                      modalData.user.type === "job-seeker"
                        ? "text-black"
                        : "text-[#E7831D]"
                    }`}
                  >
                    {capitalizeFirstLetter(modalData.user.type)}
                  </span>
                  <IoIosCheckmarkCircle
                    className={`ml-1 text-lg mr-1 ${
                      modalData.user.type === "job-seeker"
                        ? "text-black"
                        : "text-[#E7831D]"
                    }`}
                  />
                </span>
              </div>
            </div>
            <p className="text-sm mb-5 hidden md:hidden lg:block">
              {modalData.description.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line.split(/(\s+)/).map((part, i) =>
                    part.startsWith("#") ? (
                      <span
                        key={i}
                        className="text-[#E7831D] font-bold cursor-pointer transition-transform ease-in-out duration-300"
                        onClick={() => handleTagClick(part)}
                      >
                        {part}
                      </span>
                    ) : (
                      part
                    )
                  )}
                  <br />
                </React.Fragment>
              ))}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaModal;
